<template>
    <v-col>
        <v-row id="topAreaID">
            <v-col>
                <v-row class="align-center justify-space-between topRow">
                    <v-col class="text-no-wrap pb-0">
                        <HeaderTitle :title="$tc('Γραμματεία', 2)" fontSizeTitle="24px"
                            fontFamilyTitle="CenturyGothicBold" fontSizeSub="16px" fontFamilySub="CenturyGothicBold"
                            :subTitle="$t('callsMessages')" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                        <v-tabs show-arrows="always" v-model="activeTab" class="lighWhiteTab">
                            <v-tabs-slider style="height: 6px" color="error"> </v-tabs-slider>
                            <v-tab v-for="tab in tabs" :key="tab.name" :to="tab.path" exact
                                class="text-capitalize textDecorationBold tabsText">
                                {{ $t(tab.name) }}
                            </v-tab>
                        </v-tabs>
                        <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                        <router-view></router-view>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        
    </v-col>
</template>

<script>

/* 
  Name: Secretary
  Description: Secretary view is the main page for all the secrety subpages. There is a tab for each sub-page to navigate.
  
  Components Used:
    HeaderTitle
*/

import HeaderTitle from "@/components/HeaderTitle/HeaderTitle.vue";
import i18n from "@/i18n/i18n";
export default {
    name: "Secretary",
    components: {
        HeaderTitle,
    },
    data() {
        return {
            activeTab: 0,
            tabs: [
            {
                    name: i18n.t("callRegistration"),
                    path: "/secretary/callRegistration",
                },
                {
                    name: i18n.t("callManager"),
                    path: "/secretary/callManager",
                },
                {
                    name: i18n.t("callCenter"),
                    path: "/secretary/callCenter",
                },
                
                // {
                //     name: i18n.t("messageCenter"),
                //     path: "",
                // },
                // {
                //     name: i18n.t("notificationCenter"),
                //     path: "",
                // },
                // {
                //     name: i18n.t("administrative"),
                //     path: "",
                // },
                // {
                //     name: i18n.t("accounts"),
                //     path: "/secretary/accounts",
                // },
            ],
           

        }
    },
    computed: {
        // tagFilterArray() {
        //     return this.filterObject[0];
        // },
    },
    methods: {
        // async fetchSecretary() {
        //     try {
        //         this.filterObject = await ContactsHelper.setFilterObject(
        //             this.filterValues
        //         );
        //         this.closeDialog();
        //     } catch (e) {
        //         throw e;
        //     }
        // },
        // openDialog(type, data) {
        //     this.dialog = {
        //         open: true,
        //         type: type,
        //         data: data,
        //     };
        // },
        // closeDialog() {
        //     this.dialog = {
        //         open: false,
        //         type: null,
        //         data: null,
        //     };

        // },
        // updateFieldValue(key, value) {
        //     this.filterValues[key] = value;
        // },
        // async clearAllFilter() {
        //     for (const key in this.filterValues) {
        //         this.updateFieldValue(key, null);
        //     }
        //     await this.fetchContacts();
        // },
    },

}
</script>

<style scoped>
</style>